import { FORMAT_DATE_TIME_PRIMARY, AuthTypes } from "@kraftheinz/common";
import { C as CLIENTS, R as ROLES } from "./index8.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "history__container"
  }, [_c("a-table", {
    attrs: {
      "bordered": "",
      "columns": _vm.columns,
      "data-source": _vm.history,
      "row-key": "id",
      "pagination": false,
      "scroll": {
        y: 250
      }
    },
    scopedSlots: _vm._u([{
      key: "updateAt",
      fn: function(updatedAt) {
        return [_vm._v(_vm._s(_vm._f("moment")(updatedAt, _vm.FORMAT_DATE_TIME_PRIMARY)))];
      }
    }])
  })], 1);
};
var staticRenderFns$2 = [];
var EditDataHistory_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$2 = {
  name: "EditDataHistory",
  props: {
    history: {
      type: Array,
      default: null
    }
  },
  data() {
    const columns = [
      {
        dataIndex: "updatedAt",
        key: "UpdatedAt",
        title: "Updated at",
        scopedSlots: {
          customRender: "updateAt"
        }
      },
      {
        dataIndex: "updatedBy",
        key: "UpdatedBy",
        title: "Updated by"
      },
      {
        dataIndex: "status",
        key: "Status",
        title: "Status"
      },
      {
        dataIndex: "owner",
        key: "Owner",
        title: "Owner"
      },
      {
        dataIndex: "shortNote",
        key: "ShortNote",
        title: "Short Note"
      },
      {
        dataIndex: "fullNote",
        key: "FullNote",
        title: "Full Note"
      }
    ];
    return {
      FORMAT_DATE_TIME_PRIMARY,
      columns
    };
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, "cfe0092e", null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var EditDataHistory = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          staticClass: "edit-exo-data-modal",
          attrs: {
            "title": `Edit Order ${_vm.entity.order}`,
            "after-close": _vm.afterModalClose,
            "width": 832
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                key: "cancel",
                on: {
                  "click": _vm.onCancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                key: "submit",
                attrs: {
                  "type": "primary",
                  "loading": _vm.isSaving,
                  "disabled": _vm.isDisabledBtnSave()
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.saveChanges);
                  }
                }
              }, [_vm._v(" Save ")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v;
            },
            expression: "visible"
          }
        }, [_c("div", {
          staticClass: "edit-exo-data-modal__content"
        }, [_c("a-spin", {
          attrs: {
            "spinning": _vm.isLoading,
            "size": "small"
          }
        }, [_c("a-row", {
          staticClass: "mb-3",
          attrs: {
            "gutter": [24, 0]
          }
        }, [_c("a-col", {
          attrs: {
            "xs": 24,
            "sm": 12,
            "md": 8
          }
        }, [_c("date-picker", {
          attrs: {
            "disabled": !_vm.canEditOrigETD,
            "label": "Original ETD",
            "placeholder": "Select Original ETD",
            "format-date": "DD/MM/YYYY",
            "value": _vm.entity.origETD
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("origETD", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "xs": 24,
            "sm": 12,
            "md": 8
          }
        }, [_c("date-picker", {
          attrs: {
            "label": "Docs Share Date",
            "placeholder": "Select Docs Share Date",
            "format-date": "DD/MM/YYYY",
            "value": _vm.entity.docsSharedDate
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("docsSharedDate", $event);
            }
          }
        })], 1)], 1), _c("a-row", {
          attrs: {
            "gutter": [24, 0]
          }
        }, [_c("a-col", {
          attrs: {
            "xs": 24,
            "sm": 12,
            "md": 8
          }
        }, [_c("select-input", {
          attrs: {
            "reference": "export-order.common.tracking-status",
            "value": _vm.entity.trackingStatusId,
            "form-item": "",
            "source": "id",
            "source-label": "status",
            "label": "Tracking Status"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("trackingStatusId", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "xs": 24,
            "sm": 12,
            "md": 8
          }
        }, [_c("select-input", {
          attrs: {
            "reference": "export-order.common.owners",
            "value": _vm.entity.ownerId,
            "label": "Owner",
            "form-item": "",
            "source": "id",
            "source-label": "fullName",
            "placeholder": "Select an owner"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("ownerId", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "xs": 24,
            "md": 8
          }
        }, [_c("select-input", {
          attrs: {
            "reference": "export-order.common.short-notes",
            "value": _vm.entity.noteId,
            "label": "Short Note",
            "form-item": "",
            "source": "id",
            "source-label": "shortNote",
            "all-in-value": "",
            "placeholder": "Select a note reason"
          },
          on: {
            "change": _vm.onChangeShortNote
          }
        })], 1)], 1), _c("a-row", [_c("a-col", {
          attrs: {
            "xs": 24
          }
        }, [_c("text-area", {
          attrs: {
            "value": _vm.formData.notes,
            "label": "Full Note",
            "form-item": "",
            "max-length": 255,
            "disabled": !_vm.formData.noteId && !_vm.entity.noteId
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("notes", $event);
            }
          }
        })], 1)], 1), _c("a-row", [_c("a-col", {
          attrs: {
            "xs": 24
          }
        }, [_c("text-area", {
          attrs: {
            "value": _vm.formData.gbs,
            "label": "GBS Note",
            "form-item": "",
            "max-length": 255,
            "disabled": !_vm.formData.noteId && !_vm.entity.noteId
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("gbs", $event);
            }
          }
        })], 1)], 1), _c("a-row", [_c("a-col", {
          attrs: {
            "xs": 24
          }
        }, [_c("label", {
          staticClass: "mb-0"
        }, [_vm._v("History")]), _c("edit-data-history", {
          attrs: {
            "history": _vm.entity.historyExportOrders
          }
        })], 1)], 1)], 1)], 1)])];
      }
    }])
  });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditExportOrdersData",
  components: {
    EditDataHistory
  },
  inject: ["crud", "apiUrl"],
  data() {
    const { id } = this.$route.params;
    const { typeData, countryCode } = this.$route.query;
    const customApiUrl = `${this.apiUrl}/export-order/export-order-data/${id}?countryCode=${countryCode}&typeData=${typeData}`;
    const formData = {
      trackingStatusId: null,
      ownerId: null,
      noteId: null,
      notes: "",
      gbs: "",
      docsSharedDate: null,
      origETD: null
    };
    return {
      customApiUrl,
      formData,
      visible: true,
      isLoading: false,
      isSaving: false,
      entity: {}
    };
  },
  computed: {
    userInfo() {
      const { namespace, AUTH_GET_USER } = AuthTypes;
      return this.$store.getters[`${namespace}/${AUTH_GET_USER}`];
    },
    canEditOrigETD() {
      if (this.entity.typeData && this.entity.typeData.toLowerCase() !== CLIENTS.CEREBOS)
        return false;
      if (this.userInfo && this.userInfo.roles) {
        const { roles } = this.userInfo;
        if (this.isAdmin(roles))
          return true;
        if (this.isOfficer(roles))
          return !this.entity.origETD;
      }
      return false;
    }
  },
  watch: {
    entity(values) {
      if (values) {
        this.storeValues();
      }
    }
  },
  mounted() {
    this.isLoading = true;
    this.getDetail();
    this.isLoading = false;
  },
  methods: {
    async getDetail() {
      const { data } = await this.axios.get(`${this.customApiUrl}`);
      this.entity = data;
    },
    isAdmin(roles) {
      const foundAdmin = roles.findIndex((role) => role.code === ROLES.NONCORE_ADMIN || role.code === ROLES.EXO_ADMIN);
      return foundAdmin >= 0;
    },
    isOfficer(roles) {
      const foundOfficer = roles.findIndex((role) => role.code === ROLES.EXO_OFFICER);
      return foundOfficer >= 0;
    },
    isDisabledBtnSave() {
      return !this.formData || !this.formData.trackingStatusId && !this.formData.ownerId && !this.formData.notes && !this.formData.docsSharedDate && !this.formData.origETD && !this.formData.gbs;
    },
    storeValues() {
      if (this.entity) {
        Object.keys(this.formData).forEach((key) => {
          this.storeValue(key, this.entity[key]);
        });
      }
    },
    storeValue(resourceKey, value) {
      if (!resourceKey.includes(",")) {
        this.formData[resourceKey] = value;
        this.crud.updateEntity("editForm", { resourceKey, value });
        return;
      }
      const resourceKeys = resourceKey.split(",");
      resourceKeys.forEach((key) => {
        this.formData = { ...this.formData, ...value };
        this.crud.updateEntity("editForm", {
          resourceKey: key,
          value: value[key]
        });
      });
    },
    onChangeShortNote(note) {
      if (!note)
        return;
      this.storeValue("noteId,notes", {
        noteId: note.id,
        notes: note.fullNote,
        gbs: note.gbsNote
      });
    },
    async saveChanges() {
      try {
        this.isSaving = true;
        await this.crud.submitEntity("replace", this.customApiUrl);
        this.visible = false;
      } finally {
        this.isSaving = false;
      }
    },
    onCancel() {
      this.visible = false;
    },
    afterModalClose() {
      this.crud.clearEntity("editForm");
      this.$router.push({
        name: "ListExportOrdersData"
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditExportOrdersData = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-export-orders-data"
  }, [_c("resource", {
    attrs: {
      "name": "export-order.common.owners",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.common.short-notes",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.common.tracking-status",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.export-order-data",
      "resource-id-name": "order",
      "api-url": _vm.apiUrl
    }
  }, [_c("edit-export-orders-data")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditExportOrdersDataIndex",
  components: {
    EditExportOrdersData
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
